import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { validateEmail, validatePanNumber, nameValidate, MonthlyIncome, loanAmount, validateMobileNumber } from "../utils/validatiors";
import applynow_banner from "../images/apply-now.jpg";
import axios from 'axios';



export default function ApplyNow(props) {
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isValidPan, setIsValidPan] = useState(true);
    const [isValidName, setIsValidName] = useState(true);
    const [isValidMonthlyIncome, setIsValidMonthlyIncome] = useState(true);
    const [isValidLoanAmount, setIsValidLoanAmount] = useState(true);
    const [isValidMobile, setIsValidMobile] = useState(true);

    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [pincodeList, setPincodeList] = useState([]);
    const [enduseList, setEnduseList] = useState([]);
    const baseUrl = "https://api.finclouds.tech/";
    // const baseUrl = "http://localhost:8080/";
    const headerApi = {
        "Content-Type": "application/json",
        "Authorization": "Bearer ZTkxYjJlNWE5MmMzM2UxOTIxNzFjMTU2YjMwZjhlY2E=",
        'X-CSRF-Token': 'e071bcc38951400d817c204f577cc04a',
    };
    const searchParams = new URLSearchParams(window.location.search);
    const utmSource = searchParams.get('utm_source') || "WEBSITE"; // Default if not present
    const utmCampaign = searchParams.get('utm_campaign') || "ORGANIC";
    const utmMedium = searchParams.get('utm_medium') || "ORGANIC";
    const promoCode = searchParams.get('promocode') || "";

    const [formData, setFormData] = useState({
        company_id: 1,
        brand_id: 1,
        product_id: 1,
        applied_tenure: "40",
        term_and_condition: "1",
        source: "WEBSITE SK",
        utm_source: utmSource,
        utm_campaign: utmCampaign,
        utm_medium: utmMedium,
        promocode: promoCode,
        coordinates: "",
    });


    useEffect(() => {
        // Get Current Location
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                const coordinates = `${position.coords.latitude},${position.coords.longitude}`;

                // Update state with URL params and current location
                setFormData(formData => ({
                    ...formData,
                    coordinates: coordinates
                }));
            }, (error) => {
                console.error("Error obtaining location", error);
                // Handle location error or set default coordinates here if necessary
            });
        } else {
            console.log("Geolocation is not supported by this browser.");
            // Handle case where geolocation is not supported
        }
    }, []);

    const convertDataForm = () => ({
        ...formData,
        mobile: parseInt(formData.mobile, 10),
        applied_amount: parseInt(formData.applied_amount, 10),
        applied_tenure: parseInt(formData.applied_tenure, 10),
        monthly_salary: parseInt(formData.monthly_salary, 10),
        obligations: parseInt(formData.obligations, 10),
        enduse_id: parseInt(formData.enduse_id, 10),
        company_id: parseInt(formData.company_id, 10),
        gender_id: parseInt(formData.gender_id, 10),
        state_id: parseInt(formData.state_id, 10),
        city_id: parseInt(formData.city_id, 10),
        pincode: parseInt(formData.pincode, 10),
        term_and_condition: parseInt(formData.term_and_condition, 10),
    });

    const handleChange = (event) => {
        const { name, value } = event.target;

        switch (name) {

            case 'name':
                setIsValidName(nameValidate(value));
                break;
            case 'monthly_salary':
                setIsValidMonthlyIncome(MonthlyIncome(value));
                break;
            case 'applied_amount':
                setIsValidLoanAmount(loanAmount(value));
                break;
            case 'email':
                setIsValidEmail(validateEmail(value));
                break;
            case 'pan_card':
                setIsValidPan(validatePanNumber(value));
                break;
            case 'mobile':
                setIsValidMobile(validateMobileNumber(value));
                break;
            case 'state_id':
                fetchCityList(value);
                break;
            case 'city_id':
                fetchPincodeList(value);
                break;
            default:
                break;
        }
        event.target.value = value.toUpperCase();
        setFormData({ ...formData, [name]: value.toUpperCase() });
    };

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = Object.keys(formData).reduce((acc, key) => ({ ...acc, [key]: validateField(key, formData[key]) }), {});
        if (!Object.values(errors).some(error => error)) {

            const apiUrl = baseUrl + 'api/v1/web/apply-now';
            const config = {
                method: "post",
                url: apiUrl,
                headers: headerApi,
                data: convertDataForm(formData)
            };

            try {
                const response = await axios(config);
                if (response.data.status === 1) {
                    setFormData([]);
                    let referece_number = response.data.referece_number.replace("#", "");
                    navigate('/thank-you?ref=' + referece_number);
                } else {
                    setFormData([]);
                    navigate('/eligibility-failed');
                }
            } catch (error) {
                console.error('Error  :', error);
            }
        } else {
            console.log('Form has errors:', errors);
        }
    };

    const validateField = (name, value) => {
        const valueString = value.toString().trim();
        switch (name) {
            case 'mobile': return valueString.trim() ? (/^\d{10}$/.test(valueString) ? '' : 'Mobile must be 10 digits') : 'Mobile is required';
            case 'applied_amount': return valueString.trim() ? '' : 'Applied Amount is required';
            case 'applied_tenure': return valueString.trim() ? '' : 'Applied is required';
            case 'enduse_id': return valueString.trim() ? '' : 'Endsue is required';
            case 'obligations': return valueString.trim() ? '' : 'Obligation is required';
            case 'gender_id': return valueString.trim() ? '' : 'Gender is required';
            case 'state_id': return valueString.trim() ? '' : 'State is required';
            case 'city_id': return valueString.trim() ? '' : 'City is required';
            case 'pincode': return valueString.trim() ? '' : 'Pincode is required';
            case 'term_and_condition': return valueString.trim() ? '' : 'Accept the term and conditions is required';
            default: return '';
        }
    };

    useEffect(() => {
        fetchStateList();
        fetchEndList();
    }, []);

    const fetchStateList = async () => {

        const apiUrl = baseUrl + 'api/v1/web/get-state-list';

        const config = {
            method: "post",
            url: apiUrl,
            headers: headerApi,
            data: undefined
        };

        try {
            const response = await axios(config);
            setStateList(response.data.data);
            setCityList([]);
            fetchPincodeList([]);
        } catch (error) {
            console.error('Error fetching state list:', error);
        }
    };

    const fetchCityList = async (value) => {
        if (value > 0) {
            const apiUrl = `${baseUrl + 'api/v1/web/get-city-state-id'}`;
            try {
                const response = await axios.post(apiUrl, { state_id: parseInt(value, 10) }, { headers: headerApi });
                setCityList(response.data.data);
            } catch (error) {
                console.error('Error fetching state list:', error);
            }
        }
    };

    const fetchPincodeList = async (value) => {
        if (value > 0) {
            const apiUrl = `${baseUrl + 'api/v1/web/get-pincode-city-id'}`;
            try {
                const response = await axios.post(apiUrl, { city_id: parseInt(value, 10) }, { headers: headerApi });
                setPincodeList(response.data.data);
            } catch (error) {
                console.error('Error fetching state list:', error);
            }
        }
    };

    const fetchEndList = async () => {
        const apiUrl = `${baseUrl + 'api/v1/web/enduse'}`;

        const config = {
            method: "post",
            url: apiUrl,
            headers: headerApi,
            data: undefined
        };

        try {
            const response = await axios(config);
            console.log(response);
            setEnduseList(response.data.data);
        } catch (error) {
            console.error('Error fetching state list:', error);
        }
    };

    const styles = {
        row: {
            display: 'flex',
            justifyContent: 'center',
        },
        label: {
            display: 'flex',
            alignItems: 'flex-start',
            cursor: 'pointer',
            padding: '18px 1px',
            maxWidth: '100%',
            flexWrap: 'nowrap',
        },
        checkbox: {
            marginRight: '10px',
            marginTop: '3px',
            flexShrink: 0,
            width: '15px',
            height: '15px',
            WebkitAppearance: 'checkbox',
            MozAppearance: 'checkbox',
            appearance: 'checkbox',
            border: '1px solid #ccc',
            backgroundColor: 'transparent',
        },
        span: {
            flex: 1,
            textAlign: 'left',
        },
    };

    // Media query styles
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    if (mediaQuery.matches) {
        styles.label.flexWrap = 'wrap';
        styles.checkbox.marginBottom = '10px';
    }

    const term_and_condition = {
        color: "#0d6efd",
        textDecoration: "underline !important",
        fontWeight: "bold",
    };

    return (
        <>
            <title>SalaryKart - {props.title}</title>
            <div className="innrer-banner">
                <img src={applynow_banner} style={{ width: "100%" }} alt="apply-now" />
            </div>
            <div className="about-are">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="block-title">
                                <h2>Application Form</h2>
                            </div>
                        </div>
                        <div className="col-lg-12 india-kart">
                            <form id="formData" autocomplete="off" onSubmit={handleSubmit} method="post" enctype="multipart/form-data" accept-charset="utf-8">
                                <div className="row">
                                    <input type="hidden" name="gpsLocation" id="gpsLocation" />
                                    <div className="form-group col-lg-4">
                                        <label>Loan Amount<span style={{ color: "#F00 !important" }}>*</span></label>
                                        <input type="text" name="applied_amount" className="form-control my-form" onChange={handleChange} defaultValue={formData.applied_amount} placeholder="Loan Amount" required />
                                        {!isValidLoanAmount && <p style={{ color: 'red' }}>Loan Amount cannot be less than Rs. 5,000/-</p>}
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label>Monthly Income<span style={{ color: "#F00 !important" }}>*</span></label>
                                        <input type="tel" name="monthly_salary" onChange={handleChange} className="form-control my-form" defaultValue={formData.monthly_salary} placeholder="Monthly Income" required />
                                        {!isValidMonthlyIncome && <p style={{ color: 'red' }}>Minimum Salary cannot be less than Rs. 25,000/-</p>}
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label>Purpose Of Loan<span style={{ color: "#F00 !important" }}></span></label>
                                        <select className="form-control select-drop" onChange={handleChange} defaultValue={formData.enduse_id} name="enduse_id" required>
                                            <option value="">Purpose Of Loan *</option>
                                            {enduseList.map((city, index) => (<option key={index} value={city.ID}>{city.name}</option>))}
                                        </select>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="block-title">
                                            <h2>Personal Details</h2>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <label>Full Name<span style={{ color: "#F00 !important" }}>*</span></label>
                                        <input type="text" name="name" onChange={handleChange} className="form-control my-form" placeholder="Full Name" defaultValue={formData.name} required />
                                        {!isValidName && <p style={{ color: 'red' }}>Name length is acceptable from 3 to 40 characters.</p>}
                                    </div>

                                    <div className="col-lg-4">
                                        <label>Email <span style={{ color: "#F00 !important" }}>*</span></label>
                                        <input type="text" name="email" onChange={handleChange} className="form-control my-form" placeholder="Email" defaultValue={formData.email} required />
                                        {!isValidEmail && <p style={{ color: 'red' }}>Invalid email address</p>}
                                    </div>

                                    <div className="col-lg-4">
                                        <label>Mobile Number <span style={{ color: "#F00 !important" }}>*</span></label>
                                        <input type="tel" name="mobile" onChange={handleChange} maxlength="10" minlength="10" defaultValue={formData.mobile} className="form-control my-form" placeholder="Mobile Number" required />
                                        {!isValidMobile && <p style={{ color: 'red' }}>Mobile number provided is invalid</p>}
                                    </div>

                                    <div className="col-lg-4">
                                        <label>Birth Date <span style={{ color: "#F00 !important" }}>*</span></label>
                                        <input type="date" name="date_of_birth" onChange={handleChange} defaultValue={formData.date_of_birth} min="1967-09-07" max="2002-09-07" className="form-control my-form" placeholder="Birth Date" required />
                                    </div>

                                    <div className="col-lg-4">
                                        <label>PAN Card <span style={{ color: "#F00 !important" }}>*</span></label>
                                        <input type="text" name="pan_card" onChange={handleChange} className="form-control my-form" defaultValue={formData.pancard} placeholder="PAN Card" maxLength={10} required />
                                        {!isValidPan && <p style={{ color: 'red' }}>The PAN number provided is invalid</p>}
                                    </div>


                                    <div className="col-lg-4">
                                        <label>Gender <span style={{ color: "#F00!important" }}>*</span></label>
                                        <select name="gender_id" id="gender_id" onChange={handleChange} className="form-control select-drop" defaultValue={formData.gender_id} required>
                                            <option value="">Select Gender</option>
                                            <option value="1">Male</option>
                                            <option value="2">Female</option>
                                            <option value="3">Other</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-4">
                                        <label>State <span style={{ color: "#F00!important" }}>*</span></label>
                                        <select autocomplete="off" placeholder="STATE *" name="state_id" onChange={handleChange} className="form-control select-drop" data-msg="" data-rule="required" defaultValue={formData.state_id}>
                                            <option value="">SELECT STATE *</option>
                                            {stateList.map((state, index) => (<option key={index} value={state.ID}>{state.name}</option>))}
                                        </select>
                                    </div>
                                    <div className="col-lg-4">
                                        <label>City <span style={{ color: "#F00!important" }}>*</span></label>
                                        <select className="form-control select-drop" name="city_id" onChange={handleChange} placeholder="CITY*" defaultValue={formData.city_id}>
                                            <option value="">CITY *</option>
                                            {cityList.map((city, index) => (<option key={index} value={city.ID}>{city.name}</option>))}
                                        </select>
                                    </div>
                                    <div className="col-lg-4">
                                        <label>Pincode <span style={{ color: "#F00!important" }}>*</span></label>
                                        <select className="form-control select-drop" name="pincode" onChange={handleChange} defaultValue={formData.pincode} required>
                                            <option value="">Pincode *</option>
                                            {pincodeList.map((pincode, index) => (<option key={index} value={pincode.pincode}>{pincode.value}</option>))}
                                        </select>
                                    </div>

                                    <div style={styles.row}>
                                        <div>
                                            <label style={styles.label}>
                                                <input
                                                    type="checkbox"
                                                    name="term_and_condition"
                                                    required
                                                    value="1"
                                                    style={styles.checkbox}
                                                />
                                                <span style={styles.span}>
                                                    I hereby consent to receive calls, SMS, WhatsApp &amp; emails from Salarykart and have read the{' '}
                                                    <a href="terms-and-conditions" target="_blank" rel="noopener noreferrer" style={term_and_condition}>
                                                        Terms and Conditions
                                                    </a>
                                                    .
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-lg-12" style={{ textAlign: "center" }}>
                                        <button type="submit" className="btn apply-now-button">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
};
