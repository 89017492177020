import React from "react";
import repay_loan from "../images/repay-loan.jpg";
import payment_qr_idfc from "../images/payment_qr_idfc.jpeg";
import payment_qr_ubi from "../images/payment_qr_ubi.jpeg";

export default function RepayLoan(props) {
    return (
        <>
            <title>SalaryKart - {props.title}</title>
            <div className="innrer-banner" style={{ padding: "0px" }}>
                <img src={repay_loan} style={{ width: "100%" }} alt="repay-loan" />
            </div>

            <div className="contact-line">
                <div className="container">
                    <div className="row d-flex align-items-center" style={{ flexWrap: "wrap" }}>
                        {/* Image Section */}
                        <div className="col-md-6 d-flex justify-content-center" style={{ textAlign: "center" }}>
                            <div>
                                <img src={payment_qr_idfc} style={{ width: "70%", maxWidth: "300px" }} alt="payment QR" />&nbsp;
                                {/* <img src={payment_qr_ubi} style={{ width: "70%", maxWidth: "257px" }} alt="payment QR" /> */}
                            </div>
                        </div>

                        {/* Account Details Section */}
                        <div className="col-md-6">
                            <h2 className="welcome-left" style={{ fontSize: "16px", lineHeight: "25px", marginBottom: "10px" }}>
                                <strong>Please make the payment of your loan through the following bank details:</strong>
                            </h2>
                            <table
                                className="table table-bordered table-hover table-striped"
                                style={{ border: "solid 2px #f77f00", marginBottom: "0px" }}
                            >
                                <tbody>
                                    <tr>
                                        <th colSpan="2" style={{ textAlign: "center", background: "#f77f00", color: "#fff" }}>
                                            Bank Details
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>Bank Name</th>
                                        <td>IDFC FIRST Bank</td>
                                    </tr>
                                    <tr>
                                        <th>Company Name</th>
                                        <td>Konark Commercial Ltd.</td>
                                    </tr>
                                    <tr>
                                        <th>Account Number</th>
                                        <td>10188266881</td>
                                    </tr>
                                    <tr>
                                        <th>IFSC Code</th>
                                        <td>IDFB0021332</td>
                                    </tr>
                                    <tr>
                                        <th>Branch Name</th>
                                        <td>Noida Sector 62 DBU</td>
                                    </tr>
                                    <tr>
                                        <th>Account Type</th>
                                        <td>Current Account</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
